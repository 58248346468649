.App {
  text-align: center;
}

@font-face {
  font-family: eina01;
  src: url(eina-01-regular.woff);
  /* https://github.com/ionic-team/ionic-site/blob/main/content/fonts/eina/eina-01-semibold.woff */
}

.nav {
  font-family: eina01;
  font-weight: 900;
}

a {
  color: rgba(0,0,0,.5)
}

a:hover {
  color: rgba(0,0,0,.7);
  text-decoration: none;
}

@media only screen and (max-width: 700px) {
  .logo {
    width: 60vw;
    margin: 0 0 0 0px;
  }
  .heroVideo {
    height: 245px;
  }
  .subscribeImg {
    width: 15em
  }
}

@media only screen and (min-width: 700px) {
  .logo {
    width: 300px;
    margin: 0 0 0 30px;
  }
  .heroVideo {
    height: 400px;
  }
  .subscribeImg {
    width: 30em
  }
}